.solid_border[data-v-28b75808] {
  border-width: 1px;
  border-style: solid;
}
.sports_helth_font[data-v-28b75808] {
  color: #fb6522;
}
.smart_care_font[data-v-28b75808] {
  color: #007dff;
}
.sports_helth_background[data-v-28b75808] {
  background: #fb6522;
}
.smart_care_background[data-v-28b75808] {
  background: #007dff;
}
.sports_helth_border[data-v-28b75808] {
  border-width: 1px;
  border-style: solid;
  border-color: #fb6522;
}
.smart_care_border[data-v-28b75808] {
  border-width: 1px;
  border-style: solid;
  border-color: #007dff;
}
.sports_helth_shallow_font[data-v-28b75808] {
  color: rgba(251, 101, 34, 0.38);
}
.smart_care_shallow_font[data-v-28b75808] {
  color: rgba(1, 125, 255, 0.3);
}
.sports_helth_shallow_background[data-v-28b75808] {
  background: rgba(251, 101, 34, 0.38);
}
.smart_care_shallow_background[data-v-28b75808] {
  background: rgba(1, 125, 255, 0.3);
}
#sports_helth_background[data-v-28b75808] {
  background: #fb6522;
}
#smart_care_background[data-v-28b75808] {
  background: #007dff;
}
.operate[data-v-28b75808] {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  right: 24px;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.4rem;
  font-weight: bold;
  width: 2.28571429rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
  background-clip: padding-box;
}
.operate .progress[data-v-28b75808] {
  position: absolute;
  top: 0;
  height: inherit;
  opacity: 0.2;
}
.operate .ellipsis[data-v-28b75808] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.3rem;
  padding: 0 0.02857143rem;
  font-weight: bold !important;
}
.operate .ellipsis .normalfont[data-v-28b75808] {
  font-size: 0.3rem !important;
}
.operate .ellipsis .smallfont[data-v-28b75808] {
  font-size: 0.27142857rem !important;
}
.operate .text .cancelIcon[data-v-28b75808] {
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(-45%, 50%);
          transform: translate(-45%, 50%);
}
.operate_important[data-v-28b75808] {
  width: 1.65714286rem !important;
}
.operate.detail[data-v-28b75808] {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  height: 1.02857143rem;
  width: 5.14285714rem;
  position: relative;
  right: 0;
  line-height: 0.8rem;
  font-size: 0.4rem;
  font-weight: bold;
  text-align: center;
  color: white;
  border-radius: 0.68571429rem;
  color: #ffffff;
}
.installing[data-v-28b75808] {
  background: white !important;
  color: black !important;
}
.disableButton[data-v-28b75808] {
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.4rem;
  font-weight: bold;
  text-align: center;
  background: rgba(0, 0, 0, 0.05) !important;
}
.dark_mode_font[data-v-28b75808] {
  color: rgba(255, 255, 255) !important;
}
.loading[data-v-28b75808] {
  width: 0.57142857rem;
  height: 0.57142857rem;
}
.progressRatio[data-v-28b75808] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #000000;
  font-size: 0.37142857rem !important;
  padding: 0 0.17142857rem;
  font-weight: bold !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.paddingtop[data-v-28b75808] {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.paddingtop > img[data-v-28b75808] {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

html,
body,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
abbr,
acronym,
em,
img,
ins,
kbd,
q,
address,
big,
cite,
code,
del,
dfn,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
legend,
table,
caption,
tbody,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
tfoot,
thead,
tr,
th,
td,
article,
hgroup,
menu,
nav,
output,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-weight: normal;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

footer,
header,
hgroup,
menu,
nav,
article,
aside,
details,
figcaption,
figure,
section {
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

input,
button,
a {
  outline: 0 none !important;
  border: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* custom */

a {
  color: #7e8c8d;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

html,
body {
  width: 100%;
  font-family: "Arial", "Microsoft YaHei", "\9ED1\4F53", "\5B8B\4F53", "\5FAE\8F6F\96C5\9ED1", sans-serif;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  overflow-y: scroll;
}

/*清除浮动*/

.clearfix:before,
.clearfix:after {
  content: " ";
  display: inline-block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
}

/*隐藏*/

.dn {
  display: none;
}

